import * as React from "react"
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"

const VentureIndexPage = () => {
  return (
    <Layout>
      <Seo
        title="Ventures"
        description="Discover our products available in the wild"
      />
      <div className="mx-4 lg:max-w-screen-lg lg:mx-auto">
        <h1 className="text-5xl font-bold text-anomaly-red">Ventures</h1>
      </div>

      <div className="p-8 mt-8 text-center bg-white rounded-lg lg:mb-20 lg:mx-auto lg:max-w-screen-lg lg:shadow-2xl">
        <a href="https://www.relay.buzz">
          <article className="lg:max-w-screen-lg lg:mx-auto">
            <h2 className="text-5xl font-bold text-relay-green-secondary">Relay</h2>
            <p className="py-2 text-lg font-semibold text-gray-700 lg:text-2xl">Privacy focused EV charging concierge for destinations.</p>
            <StaticImage
              placeholder="dominantColor"
              src="../../images/hero-relay.png"
              alt="Relay EV hero image, showing a scene of drivers arriving and charging" />
          </article>
        </a>
      </div>

      <div className="p-8 mb-20 text-center bg-white border-t-2 border-b-2 lg:mt-8 border-rickshaw-lightblue">
        <a href="https://www.rickshaw.cloud">
          <article className="lg:max-w-screen-lg lg:mx-auto">
            <h2 className="text-5xl font-bold text-rickshaw-blue">Rickshaw</h2>
            <p className="py-2 text-lg font-semibold text-gray-700 lg:text-2xl">Last mile transport for your Stripe communication.</p>
            <StaticImage
              placeholder="dominantColor"
              src="../../images/hero-rickshaw.svg"
              alt="Rickshaw Hero, demonstrating the concept of last mile transport." />

          </article>
        </a>
      </div>

      <div className="grid grid-cols-1 gap-8 mx-4 lg:grid-cols-2 lg:max-w-screen-lg lg:mx-auto">
        {/* 
          Forces the grid row and col to start at 1 so it can
          be used as a background image for the following Link
        */}
        <div className="flex flex-1 col-start-1 row-start-1">
          <article className="flex flex-col items-center justify-between bg-white rounded-lg shadow-2xl">
            {/* 
              The style classes need to be applied to the imgClassName
              property for them to be applied to the img tag not the parent
              container div.

              Given this behaviour we could shave off a few parent classes
              */}
            <StaticImage
              imgClassName="rounded-lg"
              placeholder="dominantColor"
              src="../../images/dew-1024.png"
              alt="Dew Logo" />
          </article>
        </div>
        {/* 
          Note the following is a grid trick to layer the title
          and the link on top of the image cell rendered above
          creating the illusion of a background image.

          It essentially asks this to be rendered on top of the 
          above described layer and gives it a z-index to be
          on top of the layer above.
        */}
        <Link to="/ventures/dew" className="col-start-1 row-start-1 z-[10] p-4 flex flex-col justify-end text-center rounded-lg">
          <h2 className="text-5xl font-bold text-white">Dew</h2>
          <p className="mt-4 text-lg text-white">A <i>spiffy</i> iOS client for Hydrawise sprinkler controllers.</p>
        </Link>
        <Link to="/ventures/monstapals" className="flex flex-1">
          <article className="flex flex-col items-center justify-between bg-white rounded-lg shadow-2xl">
            <h2 className="sr-only">Monstapals</h2>
            <div className="flex flex-col justify-center p-6">
              <StaticImage
                placeholder="dominantColor"
                src="../../images/monstapals-title.svg"
                alt="Monstapals banner" />
              <p className="my-4 text-lg text-center text-gray-600">A fun-filled educational title that showcases Australian fauna. Available on iOS, Android and macOS.</p>
            </div>
            <StaticImage
              placeholder="dominantColor"
              src="../../images/monstapals-hybrids.svg"
              alt="AgriPark Hero Illustration" />
          </article>
        </Link>
      </div>

    </Layout>
  );
};
export default VentureIndexPage
